import { SET_RESOURCES } from "../actionTypes";

const initialState = {
  resources: {
    download: "",
    github: "",
    privacy: "",
    website: "",
    twitter: "",
    tech: "",
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_RESOURCES:
      return {
        ...state,
        resources: action.payload,
      };
    default:
      return state;
  }
}
