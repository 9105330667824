import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { setResources } from "../../../store/actions";
import ToTop from "../../atoms/ToTop";

import PostMarkdown from "./HouseOfJacob.md";

class HouseOfJacob extends Component {
  constructor(props) {
    super(props);

    // The body markdown goes in here
    this.state = { markdown: "" };

    // Set details for the resources panel
    this.props.setResources({
      download: "",
      github: "",
      privacy: "",
      website: "https://jcpretorius.com/blog",
      twitter: "",
      tech: "Astro, Javascript",
    });
  }

  componentDidMount() {
    // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
    fetch(PostMarkdown)
      .then((res) => res.text())
      .then((text) => this.setState({ markdown: text }));
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>House of Jacob Blog</title>
          <meta name="og:title" content="House of Jacob Blog" />
          <meta
            name="description"
            content="The blog where I write about my slightly obsessive adventures."
          />
          <meta
            name="og:description"
            content="The blog where I write about my slightly obsessive adventures."
          />
          <meta
            name="twitter:image"
            content="https://projects.jcpretorius.com/IMG/post/HouseOfJacob.png"
          />
        </Helmet>
        <ReactMarkdown source={this.state.markdown} escapeHtml={false} />

        <ToTop></ToTop>
      </div>
    );
  }
}

export default connect(null, { setResources })(HouseOfJacob);
