import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { TwitterTweetEmbed } from "react-twitter-embed";
import { setResources } from "../../../store/actions";
import ToTop from "../../atoms/ToTop";

import PostMarkdown from "./SaturdayEarth.md";

class SaturdayEarth extends Component {
  constructor(props) {
    super(props);

    // The body markdown goes in here
    this.state = { markdown: "" };

    // Set details for the resources panel
    this.props.setResources({
      download: "",
      github: "",
      privacy: "",
      website:
        "https://jcpretorius.com/post/2018/looking-back-with-saturdayearth",
      twitter: "https://twitter.com/SaturdayEarth",
      tech: "C#",
    });
  }

  componentDidMount() {
    // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
    fetch(PostMarkdown)
      .then((res) => res.text())
      .then((text) => this.setState({ markdown: text }));
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Saturday Earth Twitter Bot</title>
          <meta name="og:title" content="Saturday Earth Twitter Bot" />
          <meta name="description" content="Posts weekly Earth gifs to Twitter on Saturdays." />
          <meta name="og:description" content="Posts weekly Earth gifs to Twitter on Saturdays." />
        </Helmet>
        <ReactMarkdown source={this.state.markdown} escapeHtml={false} />

        <TwitterTweetEmbed tweetId={"1289550203800809472"} />

        <ToTop></ToTop>
      </div>
    );
  }
}

export default connect(null, { setResources })(SaturdayEarth);
