import { TOGGLE_NAV_MENU } from "../actionTypes";

const initialState = {
  navMenuOpen: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_NAV_MENU:
      return {
        ...state,
        navMenuOpen: !state.navMenuOpen,
      };
    default:
      return state;
  }
}
