import React, { Component } from "react";

class Link extends Component {
  render() {
    const { children, link } = this.props;

    var hrefLink =
      link ??
      "/" +
        children.toString().toLowerCase().replace(/ /g, "-").replace(".", "-");

    return (
      <div className="hover:underline py-1">
        <a href={hrefLink}>{children}</a>
      </div>
    );
  }
}

export default Link;
