import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { setResources } from "../../../store/actions";
import ToTop from "../../atoms/ToTop";

import PostMarkdown from "./VanillaTimeline.md";

class VanillaTimeline extends Component {
  constructor(props) {
    super(props);

    // The body markdown goes in here
    this.state = { markdown: "" };

    // Set details for the resources panel
    this.props.setResources({
      download:
        "https://chrome.google.com/webstore/detail/vanilla-timeline/igolfhjmmmclfcjjfmafbghjeelmafij",
      github: "https://github.com/jacobpretorius/Vanilla-Timeline",
      privacy: "https://github.com/jacobpretorius/Vanilla-Timeline/blob/master/PRIVACY.md",
      website: "",
      twitter: "",
      productHunt: {
        link: "https://www.producthunt.com/posts/vanilla-timeline-for-twitter",
        img:
          "https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=250690&theme=light",
        alt:
          "Vanilla Timeline for Twitter - Browse Twitter without the tweet clutter. | Product Hunt Embed",
      },
      tech: "Javascript, Chrome-extension-api",
    });
  }

  componentDidMount() {
    // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
    fetch(PostMarkdown)
      .then((res) => res.text())
      .then((text) => this.setState({ markdown: text }));
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Vanilla Timeline for Twitter</title>
          <meta name="og:title" content="Vanilla Timeline for Twitter" />
          <meta name="description" content="Clean up the noise in your Twitter feed." />
          <meta name="og:description" content="Clean up the noise in your Twitter feed." />
          <meta name="twitter:image" content="https://projects.jcpretorius.com/IMG/post/VanillaTimeline.png" />
        </Helmet>
        <ReactMarkdown source={this.state.markdown} escapeHtml={false} />

        <h2>See it in action</h2>
        <video playsInline autoPlay muted loop>
          <source src="/IMG/post/TwitterVanillaTimeline.mp4" type="video/mp4" />
          <source
            src="/IMG/post/TwitterVanillaTimeline.webm"
            type="video/webm"
          />
        </video>

        <ToTop></ToTop>
      </div>
    );
  }
}

export default connect(null, { setResources })(VanillaTimeline);
