import React, { Component } from "react";

class ToTop extends Component {
  backToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    return (
      <div className="hover:underline py-1 flex flex-row-reverse text-2xl">
        <button onClick={() => this.backToTop()}>⇈</button>
      </div>
    );
  }
}

export default ToTop;
