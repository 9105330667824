import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { setResources } from "../../../store/actions";
import { TwitterTweetEmbed } from "react-twitter-embed";
import ToTop from "../../atoms/ToTop";

import PostMarkdown from "./DiscordNFTBot.md";

class DiscordNFTBot extends Component {
  constructor(props) {
    super(props);

    // The body markdown goes in here
    this.state = { markdown: "" };

    // Set details for the resources panel
    this.props.setResources({
      download: "https://discord.com/users/200997227731222528",
      github: "https://github.com/jacobpretorius/XRPL-Discord-Bot",
      privacy: "",
      website: "https://ohonesix.com/xrpl-discord-bot",
      twitter: "https://twitter.com/jacob_pretorius",
      tech: "Typescript, Node, Docker, MongoDb, Discordjs, XRPL & Hedera (+ other blockchains compatible)",
      license:
        "Sold as fully hosted and managed Software-as-a-Service. Get in touch with me to discuss pricing.",
    });
  }

  componentDidMount() {
    // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
    fetch(PostMarkdown)
      .then((res) => res.text())
      .then((text) => this.setState({ markdown: text }));
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Discord NFT Bot</title>
          <meta
            name="description"
            content="Automate running a Discord NFT community. Let users link wallets and get server roles. Automatically update user roles if holdings change."
          />
          <meta name="og:title" content="Discord NFT Bot" />
          <meta
            name="og:description"
            content="Automate running a Discord NFT community. Let users link wallets and get server roles. Automatically update user roles if holdings change."
          />
          <meta
            name="twitter:image"
            content="https://projects.jcpretorius.com/IMG/post/DiscordNFTBotCommands.png"
          />
        </Helmet>
        <ReactMarkdown source={this.state.markdown} escapeHtml={false} />

        <TwitterTweetEmbed tweetId={"1503334987432660995"} />

        <ToTop></ToTop>
      </div>
    );
  }
}

export default connect(null, { setResources })(DiscordNFTBot);
