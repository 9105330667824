import React, { Component } from "react";
import preval from "preval.macro";
import { format } from "date-fns";
import { connect } from "react-redux";
import { toggleNavMenu } from "../../../store/actions";

const buildTimestamp = preval`module.exports = new Date().getTime();`;

class Home extends Component {
  render() {
    return (
      <div>
        <h1>
          Welcome{" "}
          <span
            className="wave-hi"
            role="img"
            aria-label="Waving hand emoji"
            title="Welcome">
            👋
          </span>
        </h1>

        <p>
          I like to make <i>things</i> and sometimes I like to share them with
          other people.
        </p>

        <p>
          You can find some of these projects{" "}
          <span
            className="underline cursor-pointer md:hidden"
            onClick={() => this.props.toggleNavMenu()}>
            here
          </span>
          <span className="hidden md:inline">on this site</span>.
        </p>

        <p>
          This is by no means a complete list, but at least for the time being
          my workflow for any project includes adding a summary to this
          directory.
        </p>

        <p>Thanks for stopping by.</p>

        <i className="border-solid border-t-2 border-gray-300 py-2 my-10 block">
          Last updated: {format(buildTimestamp, "do 'of' MMMM yyyy")}.
        </i>
      </div>
    );
  }
}

export default connect(null, { toggleNavMenu })(Home);
