import React, { Component } from "react";
import { connect } from "react-redux";

class Resources extends Component {
  render() {
    const {
      download,
      github,
      privacy,
      website,
      twitter,
      productHunt,
      tech,
      license,
    } = this.props.resources;
    const showResources =
      download.length ||
      github.length ||
      privacy.length ||
      website.length ||
      twitter.length ||
      tech.length
        ? true
        : false;

    return (
      <div className="sm-border h-full md:sticky top-0 w-full md:w-1/5">
        {showResources && (
          <div className="resources pb-8 md:pb-0 pt-0 md:mt-8 px-10 md:pl-6 md:pr-0">
            <h4 className="text-sm text-grey">Resources</h4>
            <ul>
              {download && (
                <li>
                  <a className="hover:underline py-1" href={download}>
                    Download
                  </a>
                </li>
              )}
              {github && (
                <li>
                  <a
                    className="hover:underline py-1"
                    href={github}
                    target="_blank"
                    rel="noopener noreferrer">
                    Github
                  </a>
                </li>
              )}
              {privacy && (
                <li>
                  <a
                    className="hover:underline py-1"
                    href={privacy}
                    target="_blank"
                    rel="noopener noreferrer">
                    Privacy
                  </a>
                </li>
              )}
              {twitter && (
                <li>
                  <a
                    className="hover:underline py-1"
                    href={twitter}
                    target="_blank"
                    rel="noopener noreferrer">
                    Twitter
                  </a>
                </li>
              )}
              {website && (
                <li>
                  <a
                    className="hover:underline py-1"
                    href={website}
                    target="_blank"
                    rel="noopener noreferrer">
                    Website
                  </a>
                </li>
              )}
            </ul>
            {productHunt && (
              <div className="product-hunt-badge">
                <a
                  href={productHunt.link}
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={productHunt.img} alt={productHunt.alt} />
                </a>
              </div>
            )}
            {tech && (
              <span>
                <h4 className="text-sm text-grey">Tech</h4>
                <p className="text-sm text-grey italic pl-4">{tech}</p>
              </span>
            )}
            {license && (
              <span>
                <h4 className="text-sm text-grey">License</h4>
                <p className="text-sm text-grey italic pl-4">{license}</p>
              </span>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const resources = state.resources.resources;
  return { resources };
};

export default connect(mapStateToProps, null)(Resources);
