import React, { Component } from "react";
import branding from "../../../libs/branding";

import { connect } from "react-redux";
import { toggleNavMenu } from "../../../store/actions";

const backgroundSet = {
  backgroundColor: branding.COLOR.grey,
};

class Header extends Component {
  render() {
    return (
      <div>
        <header
          className="flex flex-wrap md:justify-between p-8 px-10 md:p-6 md:px-6"
          style={backgroundSet}>
          <button
            className={`md:hidden hamburger hamburger--spin ${
              this.props.navMenuOpen ? "is-active" : ""
            }`}
            type="button"
            aria-label="Open and close main navigation menu button"
            onClick={() => this.props.toggleNavMenu()}>
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>

          <div className="text-left text-2xl serif-font px-0 font-bold">
            <a href="/">JCP Projects</a>
          </div>

          <div className="hidden md:block text-right text-l px-0 p-2 serif-font">
            <a
              href="https://jcpretorius.com/blog"
              className="px-1 hover:underline"
              target="_blank"
              rel="noopener noreferrer">
              Blog
            </a>
            |
            <a
              href="https://twitter.com/jacob_pretorius"
              className="px-1 hover:underline"
              target="_blank"
              rel="noopener noreferrer">
              Twitter
            </a>
          </div>
        </header>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { navMenuOpen: state.nav.navMenuOpen };
};

export default connect(mapStateToProps, { toggleNavMenu })(Header);
