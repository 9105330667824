import React, { Component } from "react";
import { Helmet } from "react-helmet";

class NotFound extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>404 - Not Found</title>
          <meta name="description" content="404 Error page" />
        </Helmet>
        <h1>Err, where did I put that</h1>
        <p>Oops, something went wrong or the content could not be found.</p>
        <a href="/" className="hover:underline">
          This could help
        </a>
      </div>
    );
  }
}

export default NotFound;
