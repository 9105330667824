import { TOGGLE_NAV_MENU, SET_RESOURCES } from "./actionTypes";

export const toggleNavMenu = () => ({
  type: TOGGLE_NAV_MENU,
});

export const setResources = (resources) => ({
  type: SET_RESOURCES,
  payload: resources,
});
